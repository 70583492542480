* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Encode Sans Expanded', sans-serif;
}

html, body, #root {
    height: 100%;
    width: 100%;
}